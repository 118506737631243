<template>
  <full-view title="Settings">
    <v-card flat>
      <v-card-text
        class="overflow-y-auto px-0 pt-3"
        :style="getViewHeightStyle(56)"
      >
        <v-list subheader three-line>
          <v-subheader>User Controls</v-subheader>

          <v-list-item to="profile">
            <v-list-item-avatar>
              <v-icon large>mdi-card-account-details</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{ $t('app.route.profile')}}</v-list-item-title>
              <v-list-item-subtitle>
                Manage your account profile
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item to="select-region">
            <v-list-item-avatar>
              <v-icon large>mdi-flag</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>Select Region</v-list-item-title>
              <v-list-item-subtitle>
                Select the region (country/governorate) where you live
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item to="addresses">
            <v-list-item-avatar>
              <v-icon large>mdi-map-marker-plus</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{ $t('app.route.savedAddresses')}}</v-list-item-title>
              <v-list-item-subtitle>
                Manage your list of address locations and have your orders
                delivered to your door
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item to="change-phone">
            <v-list-item-avatar>
              <v-icon large>mdi-phone-dial</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>Change Phone Number</v-list-item-title>
              <v-list-item-subtitle>
                Click here if you want to change your registed phone number
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item to="change-email">
            <v-list-item-avatar>
              <v-icon large>mdi-email</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>Change Email</v-list-item-title>
              <v-list-item-subtitle>
                Click here if you want to change your registed email address
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item to="change-password">
            <v-list-item-avatar>
              <v-icon large>mdi-key</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>Change Password</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-divider></v-divider>

        <v-list flat subheader three-line>
          <v-subheader>General</v-subheader>

          <v-list-item-group v-model="settings" active-class="">
            <v-list-item>
              <template v-slot:default="{ active }">
                <v-list-item-icon>
                  <v-icon large>mdi-web</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-menu left bottom>
                    <template v-slot:activator="{ on }">
                      <v-list-item-title v-on="on">
                        Select Language
                      </v-list-item-title>
                      <v-list-item-subtitle v-on="on" v-text="getLanguageName" />
                    </template>

                    <v-list dense style="padding: 0;">
                      <v-list-item
                        @click="changeLanguage('en')"
                      >
                        <v-list-item-title>English</v-list-item-title>
                      </v-list-item>

                      <v-divider />

                      <v-list-item
                        @click="changeLanguage('ar')"
                      >
                        <v-list-item-title>عربي</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-list-item-content>
              </template>
            </v-list-item>
            <v-list-item @click="logout">
              <v-list-item-avatar>
                <v-icon large>mdi-logout-variant</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{ $t('login.logout') }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>

        <v-divider></v-divider>

        <v-list>
          <v-list-item to="about">
            <v-list-item-avatar>
              <v-icon large>mdi-information-outline</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{ $t('app.route.about') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>
  </full-view>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import FullView from "@/common/layouts/FullView";

export default {
  name: "Settings",
  components: { FullView },
  data() {
    return {
      settings: {
        language: "en",
      },
    };
  },
  computed: {
    ...mapGetters("ui", ["getLanguage", "getMenuBadges"]),

    getLanguageName() {
      return {
        ar: "عربي",
        en: "ُEnglish",
      }[this.getLanguage];
    },
  },
  methods: {
    ...mapActions("ui", ["setLanguage"]),
    ...mapActions("auth", ["logout"]),
  },
};
</script>
